

@import 'normalize';
@import url('//use.fontawesome.com/releases/v4.7.0/css/font-awesome-css.min.css');@font-face{font-family:'FontAwesome';font-display: swap;src:url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.eot');src:url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
  url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.woff2') format('woff2'),
  url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.woff') format('woff'),
  url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.ttf') format('truetype'),
  url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.svg#fontawesomeregular') format('svg');}

/*
====================================
Default Styling
====================================
*/

img {
	max-width: 100%;
}

body {
	color: #333;
}
header{
	padding:1% 0 0 0;
}
a.link-4 {
  color:#1f253d;
  content: '';
  border-bottom: solid 1px black;

}

a:hover.link-4 {
  border-bottom: solid thin #5CAAEF;
}

a.link-4  {
  -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
}
/*new nav*/

.toggle, [id^=drop] {
  display: none;
 }
 
 nav {
   margin: 0;
   padding: 0;
   background-color: #444;
   z-index: 999;
 }
 
 #logo {
   display: block;
   padding: 0 30px;
   float: left;
   font-size: 20px;
   line-height: 60px;
 }
 
 nav:after {
   content: "";
   display: table;
   clear: both;
 }
   
   
     nav ul {
       padding: 0;
       margin: 0;
       list-style: none;
       position: relative;
     }
     
     nav ul li {
       margin: 0px;
       display: inline-block;
       float: left;
       background-color: #444;
       color:#fff;
     }
     .fa-home{
      color:#FFF;
    }
   
     nav a {
       display: block;
       padding: 0 1em;
       color: #FFF;
       font-size: .9em;
       font-weight:300;
       line-height: 60px;
       text-decoration: none;
     }
     
     nav ul li ul li:hover { background: #000000; }
     
     nav a:hover { background-color: #000000; }
     
     nav ul ul {
       display: none;
       position: absolute;
       top: 60px;
       z-index:999;
     }
     
     nav ul li:hover > ul { display: inherit; }
     
     nav ul ul li {
       width: 250px;
       float: none;
       display: list-item;
       position: relative;
     }
     
     nav ul ul ul li {
       position: relative;
       top: -60px;
       left: 170px;
     }
     
    nav ul li > a:after { content: ' +'; }
     
     li > a:only-child:after { content: ''; }
     
     
     /* Media Queries
     --------------------------------------------- */
     
     @media all and (max-width : 1200px) {
     
     
     nav { margin: 0; }
     
     .toggle + a,
      .menu { display: none; 
        color: #FFF;}
     
     .toggle {
       display: block;
       background-color: #444;
       padding: 0 20px;
       color: #FFF;
       font-size: 20px;
       line-height: 60px;
       text-decoration: none;
       border: none;
     }
     
     .toggle:hover { background-color: #000000; }
     
     [id^=drop]:checked + ul { display: block; }
     
     nav ul li {
       display: block;
       width: 100%;
     }
     
     nav ul ul .toggle,
      nav ul ul a { padding: 0 40px; font-size:.9em;}
     
     nav ul ul ul a { padding: 0 80px; }
     
     nav a:hover,
      nav ul ul ul a { background-color: #000000; }
     
     nav ul li ul li .toggle,
      nav ul ul a { background-color: #212121; }
     
     nav ul ul {
       float: none;
       position: static;
       color: #ffffff;
     }

     nav ul ul li:hover > ul,
     nav ul li:hover > ul { display: none; }
     
     nav ul ul li {
       display: block;
       width: 100%;
     }
     
     nav ul ul ul li { position: static;
     
     }
     }
     
     @media all and (max-width : 330px) {
     
     nav ul li {
       display: block;
       width: 94%;
     }
     
     }
     body > div:nth-child(2) > div > nav > ul > li:nth-child(1) > a > i{
       color:fff;
     }

	.heading{
		margin-top: 0px;
		color: rgb(7, 41, 51);
		font-size: 2em;
		line-height: 1.2em;
		font-weight: 700;
  }
  .w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
  }
  .w-container:before,
  .w-container:after {
    content: " ";
    display: table;
  }
  .w-container:after {
    clear: both;
  }
  .w-container .w-row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .iconsHome{
    font-size: 1.2em;
  padding-right:.5em;
  }
  
.headerTop{
  background: #88d8b0;
  color:#000;
}
//footer
footer{
  background: #634e42;
  padding:2%;
}
ul.cities li{
  list-style:none;
}
ul.cities a{
  color:#fff;
  font-size:.8em;
}
ul.cities a:hover{
  color:rgba(250,250,250,.7);
}
footer h2{
  text-align:center;
  color:#fff;
}
footer h3{

  text-transform: uppercase;
  text-align:center;
  color:#fff;
}
.socialsNetwork{
  width:100%;
  padding:1%;
background: #231f20;
}
.socialsNetwork p{
  color:#fff;
  font-size:.8em;
}


		  /*BROWSER*/
.browser {
  background-color: #F1F1F1;
  max-width: 100%;
  height: auto;
  border-radius: 3px;
  overflow: hidden;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.top-bar--buttons {
  height: 20px;
  background-color: #CCCCCC;
  padding-left: 5px;
}
.top-bar--buttons i {
  width: 8px;
  height: 8px;
  display: block;
  margin: 5px 0 0 5px;
  border-radius: 100%;
  float: left;
}
.top-bar--buttons .red {
  background-color: #fc635e;
  border: 1px solid #dc4441;
}
.top-bar--buttons .yellow {
  background-color: #fdbc40;
  border: 1px solid #db9c31;
}
.top-bar--buttons .green {
  background-color: #35cd4b;
  border: 1px solid #24a732;
}
.top-bar--tabs {
  position: relative;
}
.top-bar--tabs .tab {
  position: absolute;
  height: 15px;
  background: #E6E6E6;
  width: 150px;
  top: -15px;
  left: 80px;
}
.top-bar--tabs .tab::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 16px;
  position: absolute;
  border-color: transparent #E6E6E6 transparent transparent;
  border-right-style: solid;
  border-left-width: 0;
  left: -16px;
}
.top-bar--tabs .tab::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 16px;
  position: absolute;
  border-color: transparent transparent transparent #E6E6E6;
  border-left-style: solid;
  border-right-width: 0;
  right: -16px;
}
.top-bar--url {
  background-color: #E6E6E6;
  height: 20px;
}

.screenshot img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.browser:hover {
  bottom: 0px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.2);
}


/*Home page section*/
.board:after {
  clear: both;
  content: "";
  display: table;
}
.board:before {
  content: "";
  display: table;
}
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
[class*="entypo-"]:before {
  font-family: 'entypo', sans-serif;
  font-style: normal;
  display: block;
}
.board {
  width: 100%;
}
section {
  width: 33.333333333333333%;
  display: block;
  float: left;
  position: relative;
  height: 13em;
  border: 1px solid #f2f2f2;
  overflow: hidden;
}
.tile {
  padding: 2em .8em;
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-transition: top 0.55s ease-in;
  -o-transition: top 0.55s ease-in;
  -webkit-transition: top 0.55s ease-in;
  transition: top 0.55s ease-in;
}
.tile:hover {
  cursor: pointer;
}
.desc {
  top: 100%;
  padding-top: 4em;
  background: #f7f7f7 span;
  background-display: block;
  background-margin: 0 0 .75em;
}
/*** Animations ***/
@keyframes spinOut {
  0% {
    transform: rotate(0deg);
    opacity: 1.0;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
}
@keyframes spinIn {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: rotate(-360deg);
    opacity: 1.0;
  }
}
@keyframes scootOut {
  0% {
    transform: translateY(0);
  }
  5% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(110px);
  }
}
@keyframes scootIn {
  0% {
    transform: translateY(110px);
  }
  66% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes spinOut {
  0% {
    -webkit-transform: rotate(0deg);
    opacity: 1.0;
  }
  100% {
    -webkit-transform: rotate(360deg);
    opacity: 0;
  }
}
@-webkit-keyframes spinIn {
  0% {
    -webkit-transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(-360deg);
    opacity: 1.0;
  }
}
@-webkit-keyframes scootOut {
  0% {
    -webkit-transform: translateY(0);
  }
  5% {
    -webkit-transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(110px);
  }
}
@-webkit-keyframes scootIn {
  0% {
    -webkit-transform: translateY(110px);
  }
  66% {
    -webkit-transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0px);
  }
}
@-moz-keyframes spinOut {
  0% {
    -moz-transform: rotate(0deg);
    opacity: 1.0;
  }
  100% {
    -moz-transform: rotate(360deg);
    opacity: 0;
  }
}
@-moz-keyframes spinIn {
  0% {
    -moz-transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    -moz-transform: rotate(-360deg);
    opacity: 1.0;
  }
}
@-moz-keyframes scootOut {
  0% {
    -moz-transform: translateY(0);
  }
  5% {
    -moz-transform: translateY(-15px);
  }
  100% {
    -moz-transform: translateY(150px);
  }
}
@-moz-keyframes scootIn {
  0% {
    -moz-transform: translateY(150px);
  }
  66% {
    -moz-transform: translateY(-5px);
  }
  100% {
    -moz-transform: translateY(0px);
  }
}
.spin-in {
  animation: spinIn 0.4s 0.2s ease both;
  -moz-animation: spinIn 0.4s 0.2s ease both;
  -webkit-animation: spinIn 0.4s 0.2s ease both;
}
.spin-out {
  animation: spinOut 0.4s 0s ease both;
  -moz-animation: spinOut 0.4s 0s ease both;
  -webkit-animation: spinOut 0.4s 0s ease both;
}
.scoot-out {
  animation: scootOut 0.5s 0s ease both;
  -moz-animation: scootOut 0.5s 0s ease both;
  -webkit-animation: scootOut 0.5s 0s ease both;
}
.scoot-in {
  animation: scootIn 1s 0s ease both;
  -moz-animation: scootIn 1s 0s ease both;
  -webkit-animation: scootIn 1s 0s ease both;
}
.btn_primary {
  margin-top: 1em;
  position: relative;
  left: 50%;
  margin-left: -85px;
  padding: 10px;
}
.btn {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 15px;
  width: 50%;
  background: rgb(255, 115, 179);
  border-radius: 25px;
  text-transform: uppercase;
  color: #FFF;
  font-size: 1em;
  transition: ease-in 450ms;
}

.btn:hover {
  cursor: pointer;
  background: rgba(255, 115, 179, 0.7);
  box-shadow: 0px 2px 1px 1px rgba(0,0,0,0.3);
}
ul.cities li{
  list-style:none;
  
}
ul.cities a{
  color:#fff;
  font-size:.8em;
  line-height: 1.5em;
}
ul.cities a:hover{
  color:rgba(85, 32, 32, 0.7);
}
.cities-col{
  -webkit-column-count: 6;
        column-count: 6;
        -webkit-column-gap: 8em;
        column-gap: 8em;
        -webkit-column-rule: 1px dashed #cccccc;
        column-rule: 1px dashed #cccccc;
        color: #fff;
        transition: all 200ms;
    }
    ul.cities-col {
      list-style: none;

    }
   ul.cities-col > li > a{
      color:#fff;
      line-height: 2em;
      font-weight:300;
      font-size:1em;
    }
    .cities-col:hover {
        color: #1a1a1a;
        transition: all 200ms;
    }
    @media (max-width: 1200px) {
        .cities-col {
            -webkit-column-count: 4;
            column-count: 4;
        }
    }
    @media (max-width: 800px) {
        .cities-col{
            -webkit-column-count: 1;
            column-count: 1;
        }
        ul.cities-col > li > a{
          font-size:1.5em;
    }
  }
/*Sub-Menu requirements*/
ul.list { 
  margin: 0 auto; 
  padding: 0; 
  list-style: none; 
  display: table;
  width: 100%;
  text-align: center;
}
ul.list > li { 
  display: table-cell; 
  position: relative; 
  padding: 15px 0;
}
ul.list > li > a {
  color: #e64c65;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em; 
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}
ul.list > li > a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #e64c65;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
ul.list > li > a:hover:after { 
  width: 100%; 
  left: 0; 
}
aside{
  border:1px solid #C1C3D1;
  color: #666B85;
  font-weight: normal;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.1);
}
aside h3{
  margin:0;
  text-align:center;
  background: #EBEBEB;
}
aside li{
  padding:5%0;
  list-style:none;
  border-bottom: 1px solid #C1C3D1;
  text-align: center;
}
aside ul{
  margin:0;
  padding:0;
}
aside li:hover{
  background:rgb(185, 190, 240);
border-top: 1px solid #22262e;
}
aside li a:hover{
  color:#FFFFFF;
}
aside li a{
 
  vertical-align:middle;
}
/*FAQ*/
.faq-header {
  font-size: 2em;
  border-bottom: 1px dotted #ccc;
  padding: 1em 0;
}
.faq-c {
  border-bottom: 1px dotted #ccc;
  padding: 1em 0;
}
.faq-t {
  line-height: 1em;
  color: #aaa;
  font-family: sans-serif;
  float: left;
  font-weight: 700;
  padding-right: 0.3em;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}
.faq-o {
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
  -ms-transform: rotate(-45deg);
  -ms-transform-origin: 50% 50%;
  -webkit-transform: rotate(-45deg);
  -webkit-transform-origin: 50% 50%;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}
.faq-q {
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 100;
}
.faq-a {
  clear: both;
  color: #666;
  display: none;
  padding-left: 1.5em;
}

.activity-pill {
  position:relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
min-height: 140px;
margin-top: 10px;
margin-bottom: 10px;
padding: 25px 30px;
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
border-radius: 25px;
background-color: #0c1524;
-webkit-transform-origin: 50% 0%;
-ms-transform-origin: 50% 0%;
transform-origin: 50% 0%;
color: #5b769b;
}

.activity-pill.centered {
margin-top: 0px;
margin-bottom: 0px;
padding: 10px 15px;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
background-image: url('../images/quote.svg');
background-position: 96% 22px;
background-size: 50px;
background-repeat: no-repeat;
-webkit-transform-origin: 50% 0%;
-ms-transform-origin: 50% 0%;
transform-origin: 50% 0%;
}
.activity-pill p{
color: #fff;
}
.activity-sub-heading {
margin-bottom: 5px;
font-family: 'Roboto Condensed', sans-serif;
color: #fff;
font-size: 18px;
font-weight: 700;
}

.pill-description {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 10px 30px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: start;
-webkit-align-items: flex-start;
-ms-flex-align: start;
align-items: flex-start;
text-align: left;
}

.pill-description.small {
padding-right: 20px;
padding-left: 20px;
font-size: 13px;
}
.new-pill {

position: absolute;
top: 8px;
right: 8px;
z-index: 2;
padding-right: 5px;
padding-left: 5px;
border-radius: 12px;
background-color: #b00020;
color: #fff;
font-size: 10px;
line-height: 15px;
}

.new-pill.upper-right {
left:5px;
top: 5px;
right: auto;
bottom: auto;
}
.space-button-centered {
position: relative;
 margin-top: 25px;
padding:5%;
background-color: rgba(28, 46, 70, .65);
box-shadow: inset 0 0 0 1px rgba(91, 118, 155, .34);
opacity: 1;
-webkit-transition: opacity 200ms ease, box-shadow 200ms ease;
transition: opacity 200ms ease, box-shadow 200ms ease;
color: #fff;
font-size:.5em;
font-weight: 700;
letter-spacing: 1px;
text-decoration: none;
text-transform: uppercase;
}

.space-button-centered:hover {
border-color: #5b769b;
box-shadow: inset 0 0 0 1px #5b769b;
opacity: 1;
}
.button {
  background: #3700b3;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
  height: 48px;
  letter-spacing: .5px;
  line-height: 48px;
  padding: 0 24px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}
.buttonYellow {
  background: #fdbc40;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
  height: 48px;
  letter-spacing: .5px;
  line-height: 48px;
  padding: 0 24px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}


.light-gray {
  background-color: #f6f6f6;
}
.tiny-orange-call-out {
  margin-top: 5%;
  padding: 0;
  color: #cf6163;
  font-size: .9em;
  font-weight: 500;
}
.destination__sub-heading {
  margin-top: 10px;
  color: #000;
  font-size: 34px;
  line-height: 1em;
  text-transform: uppercase;
}
.destination__description {
  position: relative;
  max-width: 100%;
  margin: 5%0;
  color: rgba(28, 46, 70, 1);
  font-size: 1.2em;
  line-height: 1.7em;
  -webkit-column-count: 2;
  column-count: 2;
}
.orange-book-now {
  display: block;
  position: relative;
  margin-top: 25px;
  margin-right: 20px;
  padding: 20px 20px;
  background-color: #b71c1c;
  box-shadow: inset 0 0 0 1px rgba(91, 118, 155, .34);
  opacity: 1;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}
.orange-book-now:hover {
  background-color: #ff8a80;
  box-shadow: none;
  opacity: 1;
  color:#000;
}
.white-h2 {
  text-align: center;
  margin-top: 25px;
  color: #fff;
  font-size: 2.5em;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.backgroundImg {
  /*
background-image: url('images/SPACE.jpg');*/
  background-color: #22262e;
  background-position: 50% 0px;
  background-size: 100%;
  background-repeat: repeat-y;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  padding: 5%;
}

.text-container {
	line-height: 1.75rem;
	max-height: 200px;
	overflow: hidden;
	padding: 10px 30px 60px 30px;
	transition: max-height .5s ease;
}

.text-container.show {
	max-height: 2000px;
}

.gradient::before {
	content:'';  height:150px; position:absolute; bottom:0px; left:0; width:100%; background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%); transition:height .25s ease;
}
.show.gradient::before { height:50px;}

.show-btn {
	box-shadow:0 2px 5px rgba(0,0,0,.6); 					background-color:#88d8b0;
	border: none;
	border-radius: 100%;
	bottom: 20px;
	color: #fff;
	cursor: pointer;
	height: 40px;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	transition: background-color .25s ease;
	width: 40px;
	font-size:20px;
}

.show-btn:hover {
	background-color: #7a9c0f;
}

.show-btn.fa.rotate {transform:rotate(180deg); }

.mu-header-featured-content {
  margin-top: 80px;
  text-align: center;
}

.mu-header-featured-content h2 {
  font-size: 45px;
  color: rgb(59, 58, 58);
  font-weight: 300;
  margin-bottom: 15px;
}

.mu-header-featured-content h2 span {
  font-weight: 700;
}

.mu-header-featured-content p {
  color: rgb(32, 32, 32);
}

.mu-app-download-area {
  display: inline-block;
  margin-top: 50px;
  width: 100%;
}

.mu-app-download-area h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
}

.mu-apple-btn,
.mu-google-btn,
.mu-windows-btn {
  border: 2px solid rgb(48, 48, 48);
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  margin: 0 10px;
  padding: 11px 22px;
  text-transform: uppercase;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mu-apple-btn:hover,
.mu-google-btn:hover,
.mu-windows-btn:hover,
.mu-apple-btn:focus,
.mu-google-btn:focus,
.mu-windows-btn:focus {
  background-color: #ffffff;
}

.mu-apple-btn i,
.mu-google-btn i,
.mu-windows-btn i {
  display: block;
  float: left;
  font-size: 20px;
  line-height: 24px;
  margin-right: 10px;
}

.mu-apple-btn span,
.mu-google-btn span,
.mu-windows-btn span {
  display: block;
  float: left;
  line-height: 24px;
}
/***Earnings Page***/
.about-content{
  border-bottom: solid 0.0625em #E8EBED;
  padding-bottom: 1.25em;
margin-bottom: 1.25em;
}
.about-content p{
font-size: 0.8125em;
color: #6A6D70;
margin-top: 0.6153846154em;
}
.about-content p img{
  width: 0.625em;
height: 0.625em;
margin-left: 0.125em;
}
.perks{
  background-color: rgb(249, 249, 249);
  padding:5%;
}
.perks-container{
  background-color:#fff;
  border: solid 0.0625em #E8EBED;
  padding:2%;
  height:300px;

}
.social{
  display: flex;
  flex-direction: row;
  padding:0;
}
.social >li{
  font-size:.8em;
  margin-left:20px; 
  color:#fff;
}	
.social li a{
  color:#fff;
}
.social li a i{
  color:#fff;
}
.icon-3-col{
  width: 10.625em;
    height: 10.625em;
    margin-bottom: 0.625em;
    margin-left: -0.9375em;
}
.territory-west:hover {
  fill: #B1DBA6;
}

.territory-northeast:hover {
  fill: #79909B;
}

.territory-ksmo:hover {
  fill: #F7CC7E;
}

.territory-southeast:hover {
  fill: #EFA497;
}

.container-card {
  padding: 5%;
  background: #fff;
  display: block;
  text-align: center;
  width: 500px;
  max-width: 95%;
  margin: auto;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.padding-side {
  padding: 0 5%;
}
secondary-menu {
margin-bottom: 39px;
}
.secondary-menu {
font-weight: 600;
font-size: 1em;
text-transform: uppercase;
border-bottom: 1px solid #d9d9d7;
line-height: 52px;
margin-bottom: 49px;
}
.secondary-menu ul {
text-align: center;
list-style: none;
}

.secondary-menu li {
display: inline-block;
zoom: 1;
padding: 0 1.923%;
}
@media (max-width: 480px){
.secondary-menu li {
display: block;
margin: 5%;
padding: 0;
}
}
div.plus-list ul li {
  text-align: left;
  padding-left: 20px;
  background: url(images/valid-icon.svg) 0.2857142857em 0.3571428571em/0.7142857143em 0.7142857143em no-repeat;
}
div.plus-list li {

  font-family: inherit;
  font-size: 1.125rem;
  color: #484A4D;
  letter-spacing: 0;
  line-height: 1.5625em;
  margin: 0.75em 0;
  padding: 0em 0 0 1.875em;
}
.tile h3{
font-size:.8em;
}
.link {
margin-right: 25px;
margin-left: 25px;
font-family: Lato, sans-serif;
color: #222;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
}
.text-link {
padding-top: 45px;
padding-bottom: 45px;
position: relative;
clear: left;
border-bottom: 2px solid #5da8ef;
-webkit-transition: border-color 250ms ease, color 250ms ease;
transition: border-color 250ms ease, color 250ms ease;
font-family: Oswald, sans-serif;
color: #3f3f3f;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
}

.text-link a:hover {
border-bottom-color: #414141;
color: #5da8ef;
}

.blog_post {
  padding: 2em 0;
  border-bottom: 1px solid #ddd;
}

.article_list {
  list-style: none;
}

.blog_post-title {
  font-size: 2em;
  font-weight: 900;
  line-height: 1.4;
  border-width: .1em;
  font-family: "Fira Sans", Playfair Display, Georgia, serif;
  font-weight: 600;
}

.blog__post-meta {
  font-size: .8em;
  display: block;
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #888;
}

.secondary-nav a {
  display: block;
  width: 100%;
  border-bottom: 1px dotted;
  text-decoration: none;
  margin: .5em 0;
}
#profile-description {
  max-width: 100%;
  margin-top: 50px;
  position: relative;
}

#profile-description .text {
  /*   width: 660px;  */
  margin-bottom: 5px;
  color: #000;
  padding: 0 15px;
  position: relative;
  font-family: Arial;
  font-size: 14px;
  display: block;
}

#profile-description .show-more {
  /*   width: 690px;  */
  color: #111;
  position: relative;
  font-size: 12px;
  padding-top: 5px;
  height: 20px;
  text-align: center;
  background: #f1f1f1;
  cursor: pointer;
}

#profile-description .show-more:hover {
  color: #1779dd;
}

#profile-description .show-more-height {
  height: 65px;
  overflow: hidden;
}
.link-3 a {
  color: #00c853;
  text-decoration: none;
}

.link-3 a:hover {
  text-decoration: underline;
}